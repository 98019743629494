.offcanvas .offcanvas-body {
    font-size: 1.2rem;
}

.offcanvas{
    background-color:#fffaf7 !important;
}

.shop-container{
    border-bottom: 1px solid #ff700260;
    padding-bottom: 1rem;
}

.discount-price{
    color: #665 !important;
    text-decoration: line-through;
}

.total-price{
    color: var(--purp1);
    position: sticky;
    font-weight: 1000;
}

/******************/
.offcanvas #close{ 
    color: #f00 !important ;
    background-color: #ff0 !important;
    position: absolute;
    /* right: -100rem; */
    left: 40rem;
    width: 100vw;
    height: 200vh;
    opacity: 0;
}