.img-container3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.swiper-button-prev,
.swiper-button-next {
    color: #ffffff !important;
    font-size: 0.15rem !important;
}

.swiper-pagination-bullet-active {
    background: #fff !important;
}

