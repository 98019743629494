
.log-in-container ,.register-container {
    width: 35rem;
    margin:10rem auto;
    background-color: #e1e2ff;
    padding:2.5rem;
    font-size: 1.5rem;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 0 0 15px #e1e2ff;
}

.log-in-container input{
    direction: ltr;
    font-size: 2rem;
    padding:0 2rem;
}

.pass-icon{
    position: absolute;
    margin-right: 1.5rem;
    margin-top: 0.3rem;
    font-size: 2rem;
    cursor: pointer;
    color: var(--purp1);
}


.link-text{
    color: var(--purp1);
    font-size: 1.2rem;
}


/*********************/
/* .register-container {
    width: 30%;
    margin:15rem auto;
    background-color: #e1e2ff;
    padding:3rem;
    font-size: 1.5rem;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 0 0 15px #e1e2ff;
} */