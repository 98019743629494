.ditails{
    position: relative;
    /* height:75vh ; */
}

.ditail-container{
    font-size: 1.3rem;
}

.ditail-title{
    /* color: #55a; */
    color: var(--purp2);
}

.ditails-price-container{
    border: 4px solid var(--purp2);
    padding:2.5rem;
    border-radius:20px;
    -webkit-border-radius:20px;
    -moz-border-radius:20px;
    -ms-border-radius:20px;
    -o-border-radius:20px;
}

.color{
    width: 3.5rem;
    height: 3.5rem;
    border-radius:50%;
    -webkit-border-radius:50%;
    -moz-border-radius:50%;
    -ms-border-radius:50%;
    -o-border-radius:50%;
    display:flex;
    align-items: center;
    justify-content: center;
    margin-right: 1.2rem;
    font-size: 2.5rem;
    color: #f5f5ff;
    border: 3px solid #f5f5ff;
    outline: 1px solid #bbb;
}

.color:hover{
    cursor: pointer;
}

.active-color{
    outline: 4px solid var(--purp1);
    border: 2px solid #f5f5ff;
}


.percent{
    background-color: var(--org2);
    color: #fff;
    padding: 0.25rem 0.8rem;
    border-radius:9px;
    -webkit-border-radius:9px;
    -moz-border-radius:9px;
    -ms-border-radius:9px;
    -o-border-radius:9px;
    text-align: center;
    vertical-align: middle;
    font-size: 1.2rem;
}

.icons .icon{
    font-size: 5rem;
    color: var(--purp1);
}


.about-product-text-container{
  
  line-height: 3rem;
  margin-top: 6rem;
}

.about-product-text{
  font-size: 1.5rem;
}

.imgphoto{
  width: 100% !important;
  height: 100% !important;
}

/************    for magnifyer    ************/
.fluid {
    /* max-width: 1200px; */
    width: 100%;
    height: 100%;
    margin: 0 auto;
    /* display: flex; */
    flex-direction: row; /**/
    line-height: 1.3;
    font-size: 16px;
    direction: ltr;
  }
  
  .fluid__instructions {
    /* flex: 0 0 auto; */
    margin: 0 20px;
  }
  
  .fixed__instructions {
    flex: 1;
    margin: 0 20px;
  }
  
  a {
    color: black;
  }
  
  a:hover {
    color: #666;
  }
  

  @media (min-width: 480px) {
    .fluid {
      flex-direction: row;
    }
  
    .fluid__image-container{
      flex: 0 0 30%;
      margin: 20px 0 20px 20px;
    }
  
    .fluid__instructions {
      flex: 0 0 50%;
      padding-top: 30px;
    }
  
    .fixed__instructions {
      padding-top: 30px;
      margin: 0 10px;
    }
  
    .portal {
      position: absolute;
      top: 40px;
      left: -30px;
    }
  }