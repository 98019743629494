.to-top{
    outline: 2px solid #ffffff50;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    position: fixed;
    background-color: var(--org1);
    bottom: 0rem;
    right: 2rem;
    width: 4rem;
    height: 4rem;
    z-index: 9999;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    opacity: 0;
    line-height: 4rem;
}

.to-top.active{
    opacity: 1;
    bottom: 2rem;
}

.to-top:first-child{
    color:#fff;
    font-size: 3rem;
    text-align: center;

}