.table{
    font-size: 1.6rem;
    vertical-align: middle;
}

.table thead tr th{
    color: var(--purp1);
}

.image-container{
    width: 8rem;
}

.image-container img{
    width: 100%;
}

.price-section{
    border: 3px solid var(--purp2);
    padding: 1.5rem 2rem;
    height: 29rem;
    border-radius: 2rem ;
    -webkit-border-radius: 2rem ;
    -moz-border-radius: 2rem ;
    -ms-border-radius: 2rem ;
    -o-border-radius: 2rem ;
}

hr{
    color: var(--purp2);
}

.close-btn{
    background: transparent;
    border:none;
    outline: none;
    font-size: 2.2rem;
    color: var(--purp1);
}

.close-btn:hover{
    color: var(--purp2);
}
.close-btn:active{
    color: var(--org1);
    transform: scale(0.9);
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
}

.num-btn{
    direction: ltr;
    width: 8rem;
    
}

.num-btn input[type=number]::-webkit-inner-spin-button{
    font-size: 2.9rem;
    
}

.input-group:active{
    outline:none;
}

.inc-dic-btn{
    padding:2px;
    font-size: 2rem;
}

.discount-price{
    color: #444;
    text-decoration: line-through;
    font-size: 1.2rem;
}