.offres{
    background-color:#ff906e ;
    /* margin-top:8rem; */
    padding:4rem 0;
}

.swiper-button-prev,
.swiper-button-next {
    color: var(--org2) !important;
    font-size: 0.15rem !important;
}

.title{
    text-shadow: 1px 1px #00000050;
}
