.checkout form{
    font-size: 1.8rem;
}

.checkout form select option{
    font-family: 'Vazir' !important;
}

.necessary{
    color: #f00;
}

.form-select{
    background-position: left 0.75rem center;
}

.bill{
    /* border: 3px solid var(--org2); */
    padding: 1.5rem 2rem;
    border-radius: 2rem ;
    -webkit-border-radius: 2rem ;
    -moz-border-radius: 2rem ;
    -ms-border-radius: 2rem ;
    -o-border-radius: 2rem ;
    background-color: #e1e2ff;
}

.bill-item{
    border-bottom: 1px solid var(--purp2);
    
}

.bill-item-footer-total-price{
    color: var(--purp1);
}