header{
    /* background-color: #e1e2ff; */
    background-color: transparent;
}

.top-header {
    padding: 1.2rem 0;
}

.top-header .logo-container {
    width: 13rem;
}

.top-header .search-container {
    direction: ltr;
    width:40%;
}

.top-header .search-container input[type="text"] {
    direction: rtl;
}

.top-header .search-container .form-control{
    border: 1px solid var(--purp2);
}

.top-header .icon-container li a{
    color: var(--purp1);
    text-decoration: none !important;
    font-size:2.3rem;
    transition:all 0.1s;
    -webkit-transition:all 0.1s;
    -moz-transition:all 0.1s;
    -ms-transition:all 0.1s;
    -o-transition:all 0.1s;
}

.top-header .icon-container li a:hover{
    color: var(--purp2);
}

/* end of top header */

/* start of bottom header */


ul.menu{
    background-color: #e1e2ff;
    display:flex;
    padding: 0 7.5%;
    gap:2.3rem;
    align-items:center;
    justify-content:flex-start;
    transition: all 0.2s ;
    -webkit-transition: all 0.2s ;
    -moz-transition: all 0.2s ;
    -ms-transition: all 0.2s ;
    -o-transition: all 0.2s ;
}

/* RESPONSIVE */
.menu.active{
    right: 0%;
}
.menu.inactive{
    right: -70%;
}
/* RESPONSIVE */

ul.menu li{
    position: relative;
    padding: 1rem 0;
    
}

#close-btn{
    cursor: pointer;
}

ul.menu li a{
    text-decoration: none;
    color: var(--purp1);
    font-size: 1.3rem;
    transition: all 0.15s linear;
    -webkit-transition: all 0.15s linear;
    -moz-transition: all 0.15s linear;
    -ms-transition: all 0.15s linear;
    -o-transition: all 0.15s linear;
}

ul.menu li a:hover{
    color: var(--org1);

}

.dd-menu{
    background-color: #fff;
    z-index: 1000;
    position: absolute;
    padding: 1.5rem 1.5rem 0.75rem 3.5rem;
    margin-top:1rem;
    border-radius: 1.7rem;
    -webkit-border-radius: 1.7rem;
    -moz-border-radius: 1.7rem;
    -ms-border-radius: 1.7rem;
    -o-border-radius: 1.7rem;
    box-shadow: 1px 1px  5px #00000020;
}

.dd-menu li{ 
    padding:1rem 0;
}

.dd-menu li .dd-menu-item {
    color:var(--purp2);
    transition:all 0.15s linear;
    -webkit-transition:all 0.15s linear;
    -moz-transition:all 0.15s linear;
    -ms-transition:all 0.15s linear;
    -o-transition:all 0.15s linear;
    padding-right: 0;
}

.dd-menu li .dd-menu-item:hover {
    color:var(--purp1);
    padding-right:0.7rem;

}

/* DROP DOWN MENU */
.dd-menu.active{
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transition: all .25s ease;
    -webkit-transition: all .25s ease;
    -moz-transition: all .25s ease;
    -ms-transition: all .25s ease;
    -o-transition: all .25s ease;
}

.dd-menu.inactive{
    opacity: 0;
    visibility: hidden;
    transform: translateY(-2rem);
    -webkit-transform: translateY(-2rem);
    -moz-transform: translateY(-2rem);
    -ms-transform: translateY(-2rem);
    -o-transform: translateY(-2rem);
}
/* DROP DOWN MENU */

#shop-icon{
    position: relative;
}

.shop-item-length{
    background-color: var(--org1);
    color: #fff;
    font-size: 1.3rem;
    width:1.9rem;
    height: 1.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right:-50%;
    border-radius: 50% ;
    -webkit-border-radius: 50% ;
    -moz-border-radius: 50% ;
    -ms-border-radius: 50% ;
    -o-border-radius: 50% ;
}

@media only screen and (max-width: 576px){
    ul.menu{ 
        background-color: #e1e2ff;
        display:flex;
        flex-direction: column;
        position: absolute;
        top:0; 
        height: 100vh;
        padding: 2rem 5rem;
        z-index: 1000;
}
}

