footer{
    background-color: var(--purp1);
}

p{
    font-size: 1.3rem;
    text-align: justify;

}

.row2 #logo{
    width: 15rem;
    /* height: 10rem; */
}

.row2 #logo img{
    width: 100%;
    height: 100%;
    /* filter: brightness(0%);
    -webkit-filter: brightness(0%); */
    filter: invert(100%) grayscale(1);
    fill: #fff;
}

.row2 .links a{
    font-size: 5.3rem !important;
    color: #fff ;
    text-decoration: none !important;
    transition: all 0.15s linear;
}

.row2 .links a:hover{
    color:var(--org2);
}