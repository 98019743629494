
@font-face {
  font-family: 'Vazir';
  src: url('../public/fonts/number/vazir.eot') format('eot');
  src : url('../public/fonts/number/vazir.ttf') format('truetype'),
  url('../public/fonts/number/vazir.woff') format('woff');
}
 
:root {
  --purp1: #501fc9;
  --purp2: #693ded;
  /* --purp1: #6d4bd6;
  --purp2: #431aa9; */
  --grad1: linear-gradient(-90deg, var(--purp1), var(--purp2));
  --org1: #fb5e0d;
  --org2: #ff7002;
  --grad2: linear-gradient(-90deg, var(--org1), var(--org2));


}

html{
  font-size: 10px;
  direction: rtl;
  scroll-behavior: smooth;
}
*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
  outline: none;
  text-decoration: none;
}
body {
  direction: rtl;
  font-family: 'vazir' !important;
  background-color: #f5f5ff;
  
}

a{
  text-decoration: none;
  color: #000;
}

a:hover{
  color: #000;
}


.button {
  padding: 0.5rem 2rem;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  border: 0px solid #000;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  text-shadow: 0px 0px 1px #00000070;
  transition: background 0.1s ease-in;
  -webkit-transition: background 0.1s ease-in;
  -moz-transition: background 0.1s ease-in;
  -ms-transition: background 0.1s ease-in;
  -o-transition: background 0.1s ease-in;
}

/* .button:hover {
  background-color: var(--org1);
} */

.button:active {
  transform: scale(0.95);
}

.btn1{
  background-color: var(--purp1);
}

.btn1:hover{
  background-color: var(--purp2);
}

.btn2{
  background-color: var(--org1);
}

.btn2:hover{
  background-color: var(--org2);
}

.card{
  cursor: pointer;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  overflow: hidden;
  border: none;
  transition: transform 0.3s linear ;
  -webkit-transition: transform 0.3s linear ;
  -moz-transition: transform 0.3s linear ;
  -ms-transition: transform 0.3s linear ;
  -o-transition: transform 0.3s linear ;
  position: relative;
}

.card:hover{
  box-shadow: 0 0 15px #00000015;
}

.card span{
  background-color: var(--org2);
  color: #fff;
  padding:0.5rem;
  width:13%;
  text-align: center;
  letter-spacing: 3px;
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.3rem;
  border-radius:4px;
  -webkit-border-radius:4px;
  -moz-border-radius:4px;
  -ms-border-radius:4px;
  -o-border-radius:4px;
}

.card-title{
  line-height:2rem;
}

.price-and-btn-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.price-and-btn-container .befor-price{
  color: #555;
  text-decoration: line-through;
}

/* .price-and-btn-container .final-price{
  margin-left: -4rem;

} */